import { render, staticRenderFns } from "./CVPersonal.vue?vue&type=template&id=526cbf8e&scoped=true&"
import script from "./CVPersonal.vue?vue&type=script&lang=js&"
export * from "./CVPersonal.vue?vue&type=script&lang=js&"
import style0 from "./CVPersonal.vue?vue&type=style&index=0&id=526cbf8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526cbf8e",
  null
  
)

export default component.exports